import React from 'react';
import { ExtendCSS, Flex } from 'vcc-ui';
import SectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import SectionCover from '@vcc-www/page-section/SectionCover';
import { Link, Button } from '@vcc-www/buttons';
import { maxBackgroundWidth12Columns } from '@vcc-www/constants/layout';
import CTALinkProps from './CTALink.props';

const flexStart = 'flex-start';
const flexEnd = 'flex-end';

const justifications = {
  start: flexStart,
  end: flexEnd,
  center: 'center',
  flexStart,
  flexEnd,
};

const CTALink: React.FC<CTALinkProps> = ({
  content,
  justifyContent = 'start',
  justifyContentDesktop = justifyContent,
  fullWidth,
  containerFullWidth = false,
  buttonStyle = false,
  ...params
}) => {
  const { cta, links } = content;
  const Section = fullWidth ? SectionCover : SectionContained;
  return (
    <Section {...params} extend={sectionContainedOverride(containerFullWidth)}>
      <Flex extend={ctaWrapper({ justifyContent, justifyContentDesktop })}>
        {links?.length ? (
          links.slice(0, 2).map(({ link, trackLabel }, i) => (
            <Link key={i} {...link} trackEventLabel={trackLabel || link.text}>
              {link.text}
            </Link>
          ))
        ) : buttonStyle ? (
          <Flex extend={buttonContainerCSS}>
            <Flex>
              <Button
                variant="outline"
                trackEventLabel={cta.text}
                href={cta.href}
                target={cta.target}
                title={cta.title}
              >
                {cta.text}
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Link
            trackEventLabel={cta.text}
            href={cta.href}
            target={cta.target}
            title={cta.title}
          >
            {cta.text}
          </Link>
        )}
      </Flex>
    </Section>
  );
};

const sectionContainedOverride = (containerFullWidth?: boolean): ExtendCSS => ({
  extend: {
    condition: containerFullWidth,
    style: {
      fromL: {
        maxWidth: maxBackgroundWidth12Columns,
      },
      fromM: {
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
});

const ctaWrapper =
  ({
    justifyContent = 'start',
    justifyContentDesktop = justifyContent,
  }: {
    justifyContent: CTALinkProps['justifyContent'];
    justifyContentDesktop: CTALinkProps['justifyContentDesktop'];
  }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: justifications[justifyContentDesktop],
    gap: baselineGrid * 2,
    untilM: {
      alignItems: justifications[justifyContent],
      flexDirection: 'column',
      gap: baselineGrid,
    },
  });

const buttonContainerCSS: ExtendCSS = {
  flexDirection: 'row',
  justifyContent: 'center',
};

export default CTALink;
